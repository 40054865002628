import React from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import { Fade } from 'react-awesome-reveal'
import { graphql, useStaticQuery } from 'gatsby'
import Container from './container'
import PageHeading from './page-heading'
import TextBlock from './../components/text-block'
import QuoteBlock from './../components/quote-block'
import ArrowLink from './arrow-link'
import { GOOGLE_MAPS_LINK_TEXT } from './../constants/global-constants'
import Hospitallocation from './hospital-location'

export default function ContactDetailsSection({ cssModifier }) {
  const data = useStaticQuery(graphql`
    {
      prismicContactDetailsPage {
        data {
          email_address {
            text
          }
          email_link_label {
            text
          }
          hospital_locations {
            google_maps_link {
              url
            }
            image_1 {
              url(imgixParams: { q: 45 })
              alt
            }
            image_1_caption {
              text
            }
            image_2 {
              url(imgixParams: { q: 45 })
              alt
            }
            image_2_caption {
              text
            }
            image_3 {
              url(imgixParams: { q: 45 })
              alt
            }
            image_3_caption {
              text
            }
            location_address {
              text
            }
            location_body_text {
              html
            }
            location_heading {
              text
            }
            phone_number {
              text
            }
            website_url {
              url
            }
          }
          locations_intro_text {
            text
          }
          page_heading {
            text
          }
          sub_heading {
            text
          }
          telephone_link_label {
            text
          }
          telephone_number {
            text
          }
          nhs_intro_text {
            text
          }
          nhs_telephone_link_label {
            text
          }
          nhs_telephone_number {
            text
          }
          nhs_email_link_label {
            text
          }
          nhs_email_address {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicContactDetailsPage.data.page_heading.text,
    emailAddressText: data.prismicContactDetailsPage.data.email_address.text,
    emailLinkLabelText: data.prismicContactDetailsPage.data.email_link_label.text,
    hospitalLocations: data.prismicContactDetailsPage.data.hospital_locations,
    locationsIntroText: data.prismicContactDetailsPage.data.locations_intro_text.text,
    subHeadingText: data.prismicContactDetailsPage.data.sub_heading.text,
    telephoneLinkLabelText: data.prismicContactDetailsPage.data.telephone_link_label.text,
    telephoneNumberText: data.prismicContactDetailsPage.data.telephone_number.text,
    nhsIntroText: data.prismicContactDetailsPage.data.nhs_intro_text.text,
    nhsTelephoneLinkLabelText: data.prismicContactDetailsPage.data.nhs_telephone_link_label.text,
    nhsTelephoneNumberText: data.prismicContactDetailsPage.data.nhs_telephone_number.text,
    nhsEmailLinkLabelText: data.prismicContactDetailsPage.data.nhs_email_link_label.text,
    nhsEmailAddressText: data.prismicContactDetailsPage.data.nhs_email_address.text,
  }

  // console.log('ContactDetailsSection prismicData:', prismicData)

  return (
    <StyledComponent
      id={kebabCase(prismicData.pageHeading)}
      className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}
    >
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide" className="page-content page-content--1">
        <Fade>
          <p className="sub-heading-text text-medium">{prismicData.subHeadingText}</p>
        </Fade>
        <div className="phone-email-wrapper">
          <Fade>
            <ArrowLink
              cssModifier="phone-number"
              label={prismicData.telephoneLinkLabelText}
              url={`tel:${prismicData.telephoneNumberText}`}
              isDownloadLink={false}
            />
          </Fade>
          <Fade>
            <ArrowLink
              cssModifier="email"
              label={prismicData.emailLinkLabelText}
              url={`mailto:${prismicData.emailAddressText}`}
              isDownloadLink={false}
            />
          </Fade>
        </div>
        <Fade>
          <p className="locations-intro-text text-medium">{prismicData.locationsIntroText}</p>
        </Fade>
        <div className="google-maps-links">
          {prismicData.hospitalLocations.map((item, index) => (
            <Fade key={`google-maps-link-${index}`}>
              <ArrowLink
                cssModifier="google-maps-link"
                label={`<strong>${item.location_heading.text}</strong><br />${GOOGLE_MAPS_LINK_TEXT}`}
                url={item.google_maps_link.url}
                isDownloadLink={false}
              />
            </Fade>
          ))}
        </div>
        <Fade>
          <p className="nhs-intro-text text-medium">{prismicData.nhsIntroText}</p>
        </Fade>
        <div className="nhs-phone-email-wrapper">
          <Fade>
            <ArrowLink
              cssModifier="nhs-phone-number"
              label={prismicData.nhsTelephoneLinkLabelText}
              url={`tel:${prismicData.nhsTelephoneNumberText}`}
              isDownloadLink={false}
            />
          </Fade>
          <Fade>
            <ArrowLink
              cssModifier="nhs-email"
              label={prismicData.nhsEmailLinkLabelText}
              url={`mailto:${prismicData.nhsEmailAddressText}`}
              isDownloadLink={false}
            />
          </Fade>
        </div>
        <div className="hospitals">
          {prismicData.hospitalLocations.map((item, index) => (
            <Hospitallocation
              key={`hospital-location-${index}`}
              heading={item.location_heading.text}
              address={item.location_address.text}
              image1url={item.image_1.url}
              image1alt={item.image_1.alt}
              image1caption={item.image_1_caption.text}
              image2url={item.image_2.url}
              image2alt={item.image_2.alt}
              image2caption={item.image_2_caption.text}
              image3url={item.image_3.url}
              image3alt={item.image_3.alt}
              image3caption={item.image_3_caption.text}
              bodyText={item.location_body_text.html}
              phoneNumber={item.phone_number.text}
              websiteUrl={item.website_url.url}
            />
          ))}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding-bottom: 10rem;

  &:before {
    content: '';
    display: block;
    height: 9.85rem;
    margin-top: -9.85rem;
    width: 100%;
    pointer-events: none;

    @media (min-width: 1200px) {
      height: 9.85rem;
      margin-top: -9.85rem;
    }
  }

  .page-content {
    max-width: 70rem;

    @media (min-width: 1200px) {
      max-width: 147rem;
    }
  }

  .sub-heading-text,
  .locations-intro-text,
  .nhs-intro-text {
    padding-bottom: 1rem;
    position: relative;

    &::after {
      background-color: #262626;
      bottom: 0;
      content: '';
      display: block;
      height: 0.1rem;
      left: 0;
      position: absolute;
      width: 26rem;
    }
  }

  .arrow-link {
    &--phone-number,
    &--nhs-phone-number {
      max-width: none;

      a {
        font-size: 1.4rem;
        font-weight: 300;
        text-transform: none;

        @media (min-width: 400px) {
          font-size: 1.5rem;
        }

        @media (min-width: 500px) {
          font-size: 1.6rem;
        }

        @media (min-width: 1200px) {
          font-size: 1.8rem;
        }
      }
    }

    &--email,
    &--nhs-email {
      max-width: none;

      a {
        font-size: 1.4rem;
        font-weight: 300;
        text-transform: none;

        @media (min-width: 400px) {
          font-size: 1.5rem;
        }

        @media (min-width: 500px) {
          font-size: 1.6rem;
        }

        @media (min-width: 1200px) {
          font-size: 1.8rem;
        }
      }
    }

    &--google-maps-link {
      max-width: none;

      strong {
        font-weight: 600;
      }

      a {
        font-size: 1.4rem;
        font-weight: 300;
        text-transform: none;

        @media (min-width: 400px) {
          font-size: 1.5rem;
        }

        @media (min-width: 500px) {
          font-size: 1.6rem;
        }

        @media (min-width: 1200px) {
          font-size: 1.8rem;
        }
      }
    }
  }

  .phone-email-wrapper,
  .nhs-phone-email-wrapper {
    margin-top: 1rem;
    margin-bottom: 4rem;

    @media (min-width: 1200px) {
      display: flex;
    }

    & > div {
      @media (min-width: 1200px) {
        width: 50%;
      }
    }
  }

  .google-maps-links {
    margin-top: 1rem;
    margin-bottom: 4rem;

    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .hospitals {
    .hospital-location {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;

      @media (min-width: 1200px) {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        grid-area: primary;
        margin-bottom: 4rem;
      }

      &:nth-child(2) {
        grid-area: left-top;
        margin-bottom: 4rem;
      }

      &:nth-child(3) {
        grid-area: left-bottom;
      }

      &:nth-child(4) {
        grid-area: right-double;
      }

      .body-text {
        max-width: 124rem;

        @media (min-width: 1200px) {
          height: 100%;
        }

        a {
          color: #1265be;
          text-decoration: underline;
        }
      }
    }

    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas:
        'primary primary'
        'left-top right-double'
        'left-bottom right-double';
    }
  }
`
